
.carousel{
    width:900px;
    height:500px;
    margin: 0 auto;
}

@media (max-width: 900px) {
    .carousel {
      width:auto;
      height:auto;
    }
}

p{
  text-align: justify;
}