#navigation{
    padding-top: 0;
}

#company-name{
    font-size: 22px;
    color: rgba(255,255,255,.75);
}
.links{
    color: rgba(255,255,255,.75);
}